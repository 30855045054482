import { asyncMiddleware } from '@/router';
import { getRoute } from '@/router/router';
import { SKIP_ROUTE_CHECK } from '@/router/routes';

export const checkRouteMiddleware: asyncMiddleware = async context => {
  if (context.to.params.SKIP_ROUTE_CHECK && context.to.params.SKIP_ROUTE_CHECK === SKIP_ROUTE_CHECK) {
    return;
  }

  const route = await getRoute();

  if (context.to.name !== route) {
    return { name: route, params: context.to.params };
  }
};
