export enum ID_DOCUMENT_TYPES {
  FRONT = 'id-doc-front',
  BACK = 'id-doc-back'
}

export enum OTHER_DOCUMENT_TYPES {
  SELFIE = 'selfie',
  BANK_STATEMENT = 'bank-statement',
  DEBT_SETTLEMENT = 'debt-settlement',
  DEBT_SETTLEMENT_OFFER = 'debt-settlement-offer',
  DEBT_SETTLEMENT_RESCHEDULE = 'debt-settlement-reschedule'
}

export enum VERIGRAM_DOCUMENT_TYPES {
  ALL = 'verigram-all',
  BEST_FRAME = 'verigram-best-frame'
}

export type DOCUMENT_TYPES = ID_DOCUMENT_TYPES | OTHER_DOCUMENT_TYPES | VERIGRAM_DOCUMENT_TYPES;

export interface DocumentRequestList {
  types?: string[];
  metadata?: Record<string, string | number | boolean>;
}

export interface UploadSource {
  name: string;
  percentage: number;
  raw: File;
  status: string;
  uid: number;
  size: number;
}

export type UploadSourceList = UploadSource[];

export interface UploadEvent {
  source: UploadSource;
  sourceList: UploadSourceList;
}

export interface MediaRequestDto {
  type: DOCUMENT_TYPES;
  file: File;
  metadata?: Record<string, string | number | boolean>;
}

export type ImageModel = Partial<Record<DOCUMENT_TYPES, Document | null>>;

interface DocumentFileInfo {
  mimeType: string;
  fileSize: number;
}

interface Actor {
  id: string;
  party: string;
  realm: string;
}

export interface ActivityData {
  at: string;
  by: Actor;
}

export interface DocumentMetadata {
  [key: string]: string;
}

interface DocumentAssets {
  originalUrl: string;
}

export interface Document {
  id: string;
  type: DOCUMENT_TYPES;
  clientId: string;
  mediaId: string;
  fileInfo: DocumentFileInfo;
  location: string;
  assets: DocumentAssets;
  metadata: DocumentMetadata;
  removed: ActivityData | null;
  created: ActivityData;
  updated: ActivityData;
}
