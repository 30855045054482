import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { ApplicationState } from '@/store/modules/models/application';
import { Application, APPLICATION_STATUS, ApplicationPayload, OfferVariation } from '@/api/models/application';
import {
  acceptOffer,
  cancelApplication,
  confirmApplication,
  createApplication,
  getActiveApplication,
  getApplications
} from '@/api/application';

@Module({ name: 'application', store, dynamic: true })
class Applications extends VuexModule implements ApplicationState {
  applications: Application[] = [];
  activeApplication: Application | null = null;

  get getApplications() {
    return this.applications;
  }

  get getActiveApplication(): Application | null {
    return this.activeApplication;
  }

  get activeApplicationInFinaleState(): boolean {
    if (this.activeApplication?.status) {
      return [APPLICATION_STATUS.REJECTED, APPLICATION_STATUS.APPROVED].includes(this.activeApplication.status);
    }

    return false;
  }

  get lastApplication(): Application | null {
    return this.applications.length > 0 ? this.applications[0] : null;
  }

  get proposedOfferVariation(): OfferVariation | null {
    return this.getActiveApplication?.offer?.variations[0] ?? null;
  }

  get baseOffer(): OfferVariation | null {
    const application = this.getActiveApplication;

    if (!application) {
      return null;
    }

    const { loanAmount, term } = application;
    const paymentAmount = application.preComputation.amounts.annuityPayment;

    return { loanAmount, term: Number(term), paymentAmount };
  }

  @Mutation
  setApplications(applications: Application[]) {
    this.applications = applications;
  }

  @Mutation
  addApplication(application: Application) {
    this.applications.push(application);
  }

  @Mutation
  setActiveApplication(application: Application) {
    this.activeApplication = application;
  }

  @Mutation
  resetActiveApplication() {
    this.activeApplication = null;
  }

  @Action
  async initGetApplications() {
    try {
      const { data } = await getApplications();
      this.setApplications(data);
      return true;
    } catch (e) {
      return false;
    }
  }

  @Action
  async initGetActiveApplication() {
    try {
      const { data } = await getActiveApplication();
      if (data) {
        this.setActiveApplication(data);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  @Action({ rawError: true })
  async initCreateApplication(payload: ApplicationPayload) {
    const { data } = await createApplication(payload);
    this.addApplication(data);

    return true;
  }

  @Action({ rawError: true })
  async declineOffer() {
    const activeApplication = this.getActiveApplication;

    if (!activeApplication) {
      return;
    }

    return cancelApplication(activeApplication.id, 'Offer declined');
  }

  @Action({ rawError: true })
  async confirmApplication() {
    const activeApplication = this.getActiveApplication;

    if (!activeApplication) {
      return;
    }

    return confirmApplication(activeApplication.id);
  }

  @Action({ rawError: true })
  async acceptOffer() {
    const activeApplication = this.getActiveApplication;
    const offer = this.proposedOfferVariation;

    if (!activeApplication || !offer) {
      return;
    }

    return acceptOffer(activeApplication.id, offer);
  }

  @Action
  reset() {
    this.setApplications([]);
    this.resetActiveApplication();
  }
}

export const applications = getModule(Applications);
