import { LocaleMessageObject } from 'vue-i18n';
import { GENDER, PROFILE_ADDITIONAL_INFO_KEYS } from '@/api/models/client';
import { FORM_ERRORS } from '@/components/registration/settings/rules';
import { AGREEMENT_DOCUMENT_TYPE } from '@/views/profile/Documents/type';
import { applyMask, mobilePhoneMask } from '@/components/registration/settings/mask';
import { FileMaxSize } from '@/utils/file';
import { PROFILE_ROUTES } from '@/router/routes_type';
import { GRID_ROW_TYPE } from '@/components/text/types';
import { DISBURSEMENT_TYPE } from '@/api/models/disbursement';
import { DebtSettlementApplicationStatus } from '@/api/models/debtSettlementApplication';
import { OTHER_DOCUMENT_TYPES } from '@/api/models/document';

const phoneNumber = applyMask('+77080750888', mobilePhoneMask);

export const locale: LocaleMessageObject = {
  LANGUAGES: {
    RU: 'RU',
    KK: 'KZ',
    OPTIONS: {
      RU: 'Русский',
      KK: 'Казахский'
    }
  },
  COMMON: {
    BACK_BUTTON: 'Оралу'
  },
  PAYMENT_GATEWAY: {
    TRANSACTION: {
      SUCCESS: 'Төлем сәтті аяқталды',
      ERROR: 'Төлем жасалмады'
    }
  },
  NOTIFICATION: {
    PASSWORD_CHANGED: {
      TITLE: 'Құпия сөз өзгертілді',
      MESSAGE: 'Енді сіз жаңа құпия сөзбен кіре аласыз'
    },
    LOCALE_CHANGED: {
      TITLE: 'Язык коммуникации успешно изменен',
      MESSAGE: 'Теперь язык коммуникации: {language}'
    },
    PASSWORD_RESET: {
      NEW_CODE_SENT: 'Код хабарламасы жіберілді'
    },
    EMAIL_CHANGE: {
      NEW_CODE_SENT: 'Жаңа код email-ға жіберілді',
      SUCCESS: 'Email сәтті өзгертілді',
      ERROR: 'Email ауыстыру кезіндегі қате'
    },
    APPROVE_OFFER: {
      ERROR: 'Ұсынысты растау мүмкін болмады'
    },
    PHONE_CHANGE: {
      NEW_CODE_SENT: 'Новый SMS-код отправлен',
      SUCCESS: 'Телефон успешно изменен',
      ERROR: 'Ошибка при смене телефона'
    },
    DOCUMENT_UPLOAD: {
      ERROR: 'Ошибка при загрузке'
    },
    SMS_CODE: {
      TITLE: 'Новый SMS-код отправлен'
    },
    IMAGE_UPLOAD: {
      INCORRECT_IMAGE_FILE_FORMAT: 'Суреттің форматы дұрыс емес. Формат jpeg немесе png болуы керек',
      INCORRECT_IMAGE_FILE_SIZE: `Бастапқы кескіннің өлшемі қажетті форматқа сәйкес келмейді, ${FileMaxSize.IMAGE} МБ аспайтын фотосуретті жүктеп алыңыз.`
    },
    CARD_UNILNK: {
      SUCCESS: 'Банк картасы тексеруден өткен жоқ',
      ERROR: 'Картаны тексеруді болдырмау қатесі'
    }
  },
  MENU: {
    CREDIT_FOR: 'Микрокредит мақсаты:',
    HOW_TO_RETURN: 'Қалай қайтаруға болады',
    FAQ: 'Жиі қойылатын сұрақтар',
    NEWS: 'Жаңалықтар',
    CONTACTS: 'Байланыстар',
    DOCUMENTS: 'Құжаттар',
    REPORTS: 'Есептер',
    PHONE_NR: phoneNumber,
    AUTH: 'Кіру',
    PROFILE: 'Менің профиль',
    LOGOUT: 'Шық-'
  },
  LAYOUT_SECTIONS: {
    SECTION_MAIN: {
      LINE_1: '3 000 000 теңгеге дейін ШАҒЫН НЕСИЕ',
      LINE_2: '4,8%-ден бастап жылдық пайыз, 36 айға дейін ыңғайлы кесте',
      LINE_3: 'Salem.kz шұғыл ақша керек кезде ғана көмектесумен бірге табыс табуға да көмектеседі!',
      BUTTON: 'Шағын несие алу',
      LINE_4: 'Біз туралы'
    },
    SECTION_ANY_GOALS: {
      LINE_1: 'Онлайн кредит кез-келген мақсатқа',
      LINE_2:
        'Salem.kz сізге басқа банктегі несиені қайта қаржыландыруға, жылжымайтын мүлікті, автокөлікті жөндеуге немесе сатып алуға, медициналық немесе білім беру қызметтеріне ақы төлеуге, іс-шараны ұйымдастыруға және т.б. көмектеседі.',
      CARD_1: 'Қайта қаржыландыру',
      CARD_2: 'Бизнес үшін',
      CARD_3: 'Медициналық қызметтер',
      CARD_4: 'Жөндеу және құрылыс',
      CARD_5: 'Автокөлікті жөндеу'
    },
    SECTION_BUSINESS: {
      LINE_1: 'Salem.kz  сайтында  онлайн-кредит берудің артықшылықтары шағын бизнес иелері үшін:',
      LINE_2: 'Қысқа сауалнаманы онлайн толтырыңыз және 3 000 000 теңгеге дейінгі сомаға тез арада шешім алыңыз!',
      BUTTON: 'Шағын несие алу'
    },
    SECTION_FAQ: {
      LINE_1: 'Жиі қойылатын сұрақтар'
    },
    SECTION_WHY_SALEM: {
      TITLE: 'Неге дәл Salem.kz?',
      SECTIONS: {
        ONLINE: {
          TITLE: 'Бүкіл процесс <br>толығымен онлайн',
          CONTENT:
            '<b>Бізбен ыңғайлы!</b> Salem.kz біздің өміріміздің ең қымбат ресурсы – уақытқа – ұқыпты қарайды. Біз онлайн микрокредит алуға көмектесе отырып, Сіздің уақытыңызды үнемдейміз және Сіз үшін тиімді шарттарда!'
        },
        PERCENTAGE: {
          TITLE: 'Төмен <br>пайыздық мөлшерлемелер',
          CONTENT:
            '<b>Бізбен бірге пайдалы!</b> Біз ірі сомаларды ұзақ мерзімге, нарықтағы ең ТӨМЕН пайыздық мөлшерлемелердің бірі – банк өнімдері деңгейінде береміз. Salem.kz сайтында қысқа сауалнаманы толтырыңыз және интернеттегі өнімнің барлық артықшылықтарын алыңыз!'
        },
        SAFE: {
          TITLE: 'Қауіпсіздік <br>кепілі',
          CONTENT:
            '<b>Бізбен сенімді!</b> Salem.kz-қызметі Қазақстан Республикасының Ұлттық Банкімен реттелетін толықтай ашық компания.Salem.kz сайтында микрокредитті шұғыл онлайн рәсімдеуге Қазақстанның кез келген нүктесінен болады. Salem.kz көмегімен сіз банктердің жұмыс кестесіне және олардың орналасқан жеріне тәуелді болмайсыз. Бізде банк сияқты, тек одан да жақсы.'
        }
      }
    },
    SECTION_8: {
      LINE_1: 'Марапаттар және рейтингтер',
      LINE_2: 'Біз – ашық жұмыс істейтін компаниямыз, біздің қызметті Қазақстан Республикасы Ұлттық банкі қадағалайды.',
      LINE_3:
        'Өзіңізбен смартфон мен ЖСН болған кезде де несие алуға өтінім бере аласыз. Сауалнама толтыруға онлайн 8 минуттай уақыт кетеді, шешім бірден қабылданады. Қаражат клиенттің толтыру кезінде көрсеткен картасына немесе банктік шотына жіберіледі',
      LINE_4:
        'Өзіңізбен смартфон мен ЖСН болған кезде де несие алуға өтінім бере аласыз. Сауалнама толтыруға онлайн 8 минуттай уақыт кетеді, шешім бірден қабылданады.',
      LINE_5: 'Қаражат клиенттің толтыру кезінде көрсеткен картасына немесе банктік шотына жіберіледі.'
    },
    SECTION_QUESTIONNAIRE: {
      TITLE: 'Сауалнаманы толтырыңыз',
      SUBTITLE: 'және тез арада шешім алыңыз!',
      BUTTON: 'Шағын несие алу'
    },
    SECTION_ABOUT: {
      LINE_1: 'Salem.kz жобасы туралы',
      LINE_2:
        '«Салем Кредит» МҚҰ – сенімді, клиентке бағдарланған компания. Біз финтех-индустрияның бізбен жұмыс істейтін танымал мамандар тобының арқасында кредиттік скоринг және қаржылық технологиялар саласындағы сарапшымыз.',
      LINE_3: 'Кредитті қалай береміз',
      LINE_4:
        'Біз тұтынушыларды тез арада анықтау және бағалау үшін жаңа технологияларды қолданамыз, бұл бізге клиенттердің қажеттіліктерін мүмкіндігінше тез қанағаттандыруға мүмкіндік береді. Қолыңызда тек смартфон мен ЖСН бар болса, микрокредитке онлайн өтінім бере аласыз. Өтінімді ресімдеудің өзі санаулы минуттарды алады, ал клиенттің төлем қабілеттілігін бағалаудың толық автоматтандырылған процесі жүйеге дереу шешім қабылдауға мүмкіндік береді. Микрокредитті тез арада ғана емес, үйден шықпай-ақ немесе кеңседен шықпай-ақ алуға болады, аумақтық орналасу да ешқандай рөл атқармайды. Ақша клиенттің атына тіркелген картаға немесе банк шотына түседі.',
      LINE_5: 'Халықаралық компания',
      LINE_6:
        'Salem.kz жобасы филиалдары Латвия, Эстония, Польша, Дания, Чех Республикасы, Мексика, Швеция, Қырғызстан және Вьетнамда орналасқан Еуропадағы онлайн-кредит беру саласындағы ең жылдам дамып келе жатқан халықаралық холдинг – SunFinance қаржы тобының құрамына кіреді. SunFinance-ті тұтынушылық несиелеу нарығында 10 жылдан астам уақыт жұмыс істеп келе жатқан және өз қызметінде кредит беру туралы шешім қабылдау жүйесіндегі озық әзірлемелерді жауапты несиелеу принциптерімен үйлестіретін кәсіпқойлар тобы басқарады.',
      LINE_7: 'Қазақстанда онлайн-кредит дегеніміз не?',
      LINE_8:
        'Онлайн-кредит – бұл 3 000 000 теңгеге дейінгі мөлшердегі ақша қаражаты, ол кез келген мақсатқа 3 жылға дейінгі мерзімге берілуі мүмкін. Кредит ай сайынғы аннуитеттік (яғни тең сомалармен бөлінген) төлемдер арқылы өтеледі.Онлайн-кредиттер заманауи өмірдің ең қымбат ресурсы – уақытты – үнемдейді! Құжаттарды жинау және қиын кезектер туралы ұмытыңыз. Кредитке өтінім Қазақстанның кез келген жерінен онлайн рәсімделеді, ол үшін тек қана интернетке қолжетімділік, кез келген құрылғы: мобильді, планшет немесе ноутбук және Сіздің уақытыңыздың бірнеше минуты қажет. Өтінім мақұлданғаннан кейін 15 минут ішінде ақша банктік шотқа немесе картаға жіберіледі. Микрокредитті  картаға өтініш берген күні алуға болады.',
      LINE_9: 'Біздің шарттар',
      LINE_10:
        'Жеке тұлғаларға кредит беру шарттары мен мөлшерлемелер банк өнімдеріне барынша жақындатылған. Мөлшері 3 000 000 теңгеге дейінгі тұтынушылық кредиттер кепілсіз 3 жылға дейінгі мерзімге беріледі, пайыздық мөлшерлеме Қазақстан Республикасы Ұлттық Банкінің талаптарына толық сәйкес келеді.BigData-ға негізделген шешімдер қабылдау және несие скорингінің жетілдірілген модельдерін қолдану барлық процестердің жылдамдығы мен дәлдігін қамтамасыз етеді.Біздің жүйені құру кезінде біз онлайн-несиелеу нарығының басқа жүйелерінің қателіктері мен кемшіліктерін қарастырдық, бұл айтарлықтай тиімді жұмыс істейтін несиелік құбырдың өзіндік бірегей жүйесін құруға мүмкіндік берді.'
    }
  },
  FOOTER: {
    LINK_1: 'Бизнес үшін',
    LINK_2: 'Сайттың картасы',
    LINK_3: 'Жиі қойылатын сұрақтар',
    LINK_4: 'Жазба пікірлер',
    LINK_5: 'Құжаттар',
    LINK_6: 'Байланыстар',
    LINK_NEWS: 'Жаңалықтар',
    ADDRESS:
      '<strong>Заңды мекен-жайы:</strong> Қазақстан Республикасы, 050012, Алматы қ., Алмалы ауданы, Сейфуллин даңғылы, № 502 үй, 8 қабат',
    PHONE: `<strong>Телефон:</strong> ${phoneNumber}`,
    PARTNERS: 'Sun finance в мире',
    COPYRIGHTS: '2020 Copyrights - Salem.kz - All Rights Reserved.',
    LICENSE:
      '"Салем Кредит «Микроқаржы ұйымы» ЖШС" микроқаржы қызметіне лицензиясы № 02.21.0060.М 31.03.2021 ж. СЖТМ, сыйақының жылдық min 19.9 %, max 56%'
  },
  NEWS: {
    TITLE: 'Жаңалықтар'
  },
  LOGIN: {
    TITLE: 'Профильге кіру',
    FORGOT_PASSWORD: 'Құпия сөзді ұмыттыңыз ба?',
    ENTER: 'Кіру',
    EMAIL_OR_INN: 'Сіздің ЖСН',
    PASSWORD: 'Сіздің кұпия сөз'
  },
  FORGOT_PASSWORD: {
    TITLE: 'Құпия сөзді ұмыттыңыз ба?',
    SUBTITLE: 'Қалпына келтіріңіз және жаңасын алыңыз',
    METHODS: {
      EMAIL: {
        TITLE: 'Электрондық пошта арқылы',
        SUBTITLE: 'Біз Сіздің эл.поштаңызға құпиясөзді қалпына келтіру үшін код жібереміз'
      },
      PHONE: {
        TITLE: 'SMS арқылы',
        SUBTITLE: 'Біз Сіздің ұялы телефоныңызғаSMS арқылы  құпиясөзді қалпына келтіру үшін код жібереміз'
      }
    },
    METHOD_FORM: {
      SUBMIT: 'Жіберу',
      OR_METHOD: 'НЕМЕСЕ',
      CONTACT_SUPPORT: 'Егер сіз поштаны ұмытып қалсаңыз, сайттағы нөмір бойынша қолдау қызметіне хабарласыңыз',
      ANOTHER_METHOD: 'қалпына келтіру әдісі {link}'
    },
    EMAIL_METHOD: {
      TITLE: 'Құпия сөзді қалпына келтіру үшін, тіркеу кезінде көрсетілген e-mail енгізіңіз',
      PHONE_METHOD: 'SMS'
    },
    PHONE_METHOD: {
      TITLE: 'Құпия сөзді қалпына келтіру үшін, тіркеу кезінде көрсетілген ұялы телефон нөмірін енгізіңіз',
      EMAIL_METHOD: 'Эл.пошта'
    },
    VERIFICATION: {
      TITLE: 'Құпия сөзді қалпына келтіру жіберілді.',
      SUBMIT: 'Жіберу',
      CODE_NOT_RECEIVED: 'Растау коды келген жоқ па?',
      SEND_CODE_AGAIN: 'Кодты қайта жіберу',
      EMAIL: {
        TITLE: 'Көрсетілген e-mail-ге растау коды жіберілді.<br> Берілген ұяшыққа осы кодты енгізіңіз',
        NEW_CODE_SENT: 'Жаңа код email-ға жіберілді'
      },
      PHONE: {
        TITLE: 'Көрсетілген телефон нөміріне растау коды жіберілді.<br> Берілген ұяшыққа осы кодты енгізіңіз',
        NEW_CODE_SENT: 'Қалпына келтіру әдісі SMS'
      }
    }
  },
  RESET_PASSWORD: {
    TITLE: 'Жаңа құпия сөзді енгізіңіз',
    TITLE_SAVED: 'Сіздің құпия сөзіңіз сәтті өзгертілді',
    SUBMIT: 'Сақтау',
    LOGIN: 'Кіру'
  },
  REGISTRATION: {
    TITLE: 'Жаңа клиент',
    REGISTER: 'Тіркеу',
    INFO_1: 'Егер Сіз бұрын Salem.kz қызметтерін пайдалансаңыз, онда Сіз біздің сайтта тіркеліп, өтініш беруіңіз керек',
    INFO_2: 'Жаңа клиенттер үшін 10* минут ішінде 3 000 000 теңгеге дейін қолжетімді!',
    CAPTION: '*ЭЦҚ-мен қол қойылған жағдайда'
  },
  REGISTRATION_LOADING: {
    TITLE: 'Ақпарат өңделуде',
    SUB_TITLE: 'Бірнеше секунд күтуді сұраймыз'
  },
  REGISTRATION_INCOME: {
    TITLE: 'Кіріс туралы ақпаратты жаңартыңыз'
  },
  REGISTRATION_FINANCES: {
    BANK_STATEMENT: 'Мәлімдеме:',
    ATTENTION: 'Назар аударыңыз мәлімдемеде <b>соңғы 6 айдағы</b> ақпарат болуы тиіс',
    UPLOAD_TITLE: 'Жалғастыру үшін келесі ақпараттардың біреуін жүктеңіз:',
    UPLOAD_INFO:
      'Депозит бойынша үзінді көшірме (Kaspi/HALYK/ТҚЖБ немесе өзіңіздің банкіңіз) немесе соңғы <b>6 айдағы</b> кез келген банктегі төлем картаңыздың шоты бойынша үзінді көшірме'
  },
  REGISTRATION_PERSONAL_DATA: {
    TITLE: 'Жеке кабинет құрыңыз',
    RULE: 'Барлық жолдар толтырылуы керек',
    POPUP_TITLE: 'Телефонды тексеру',
    POPUP_RULE:
      'Бұл Cіздің телефон нөміріңіз екеніне көз жеткізуіміз керек. Сізбен көрсетілген нөмірге {hiddenPhoneNumber}, коды бар SMS жіберілді.',
    POPUP_SMS_CODE_SEND_AGAIN: 'SMS-кодты қайта жіберу',
    POPUP_SMS_CODE_NOT_SEND: 'Телефонға код алған жоқсыз ба?',
    POPUP_CONSENTS_ALL_CHECKED: 'Мен жоғарыда жазылған келісімдермен және міндеттемелермен таныстым және келісемін\n'
  },
  REGISTRATION_TAKE_LOAN: {
    TITLE: 'Соманы таңдаңыз'
  },
  REGISTRATION_DOCUMENTS: {
    TITLE: 'Жеке куәлік кескінін жүктеңіз',
    HELPER: 'Фотосуреттерді қалай жүктеуге болады?',
    IDENTITY: {
      TITLE: 'Жеке куәліктің кескіні',
      FRONT: 'Жеке куәліктің кескінін жүктеңіз (алдыңғы жағы)',
      BACK: 'Жеке куәліктің кескінін жүктеңіз (артқы жағы)',
      SELFIE: 'Өз селфиіңізді жүктеңіз'
    },
    MODAL: {
      TITLE: 'Фотосуреттерді қалай жүктеуге болады?'
    },
    MODAL_WITH_SELFIE: {
      CONTENT: [
        {
          TYPE: GRID_ROW_TYPE.IMAGES,
          ROW: [
            {
              img: '<img src="/img/helper/acceptable.png" width="64" height="65" alt="Сәйкес формат" />',
              legend: 'Сәйкес формат'
            },
            {
              img: '<img src="/img/helper/boards-visible.png" width="64" height="64" alt="Көрінетін жиектер" />',
              legend: 'Көрінетін жиектер'
            },
            {
              img: '<img src="/img/helper/not-blurry.png" width="64" height="64" alt="Бұлыңғыр емес" />',
              legend: 'Бұлыңғыр емес'
            },
            {
              img: '<img src="/img/helper/no-flash.png" width="64" height="64" alt="Шағылыспайды" />',
              legend: 'Шағылыспайды'
            }
          ]
        },
        {
          ROW: 'Жеке куәлікті бет және артқы жағынан жүктеу.'
        },
        {
          ROW: 'Өзіңіздің бетіңіз анық көрінетін селфи-фотосуретін жүктеңіз.'
        },
        {
          ROW:
            'Қазақстан Республикасы Қаржы нарығын реттеу және дамыту агенттігі басқармасының 2021 жылғы 30-сәуірдегі ' +
            '№63 қаулысына сәйкес қарызгердің тұлғалығын міндетті биометрикалық сәйкестендіру енгізілген, Сізден ' +
            'жеке тұлғалықты куәландыратын құжаттардың суреттеріңізді, селфиді жүктеу арқылы сәйкестендіруді өтуді ' +
            'сұраймыз.'
        },
        {
          ROW:
            'Біз Сіздің құпиялылығыңызға аса байсалдылықпен қараймыз. Сіздің дербес ақпаратыңыз қатаң қауіпсіздікте ' +
            'сақталынады.'
        }
      ]
    },
    MODAL_WITHOUT_SELFIE: {
      TITLE: 'Фотосуреттерді қалай жүктеуге болады?',
      CONTENT: [
        {
          TYPE: GRID_ROW_TYPE.IMAGES,
          ROW: [
            {
              img: '<img src="/img/helper/acceptable.png" width="64" height="65" alt="Сәйкес формат" />',
              legend: 'Сәйкес формат'
            },
            {
              img: '<img src="/img/helper/boards-visible.png" width="64" height="64" alt="Көрінетін жиектер" />',
              legend: 'Көрінетін жиектер'
            },
            {
              img: '<img src="/img/helper/not-blurry.png" width="64" height="64" alt="Бұлыңғыр емес" />',
              legend: 'Бұлыңғыр емес'
            },
            {
              img: '<img src="/img/helper/no-flash.png" width="64" height="64" alt="Шағылыспайды" />',
              legend: 'Шағылыспайды'
            }
          ]
        },
        {
          ROW: 'Жеке куәлікті бет және артқы жағынан жүктеу.'
        },
        {
          ROW:
            'Қазақстан Республикасы Қаржы нарығын реттеу және дамыту агенттігі басқармасының 2021 жылғы 30-сәуірдегі ' +
            '№63 қаулысына сәйкес қарызгердің тұлғалығын міндетті биометрикалық сәйкестендіру енгізілген, Сізден ' +
            'жеке тұлғалықты куәландыратын құжаттардың суреттерін жүктеу арқылы сәйкестендіруді өтуіңізді сұраймыз.'
        },
        {
          ROW:
            'Біз Сіздің құпиялылығыңызға аса байсалдылықпен қараймыз. Сіздің дербес ақпаратыңыз қатаң қауіпсіздікте ' +
            'сақталынады.'
        }
      ]
    }
  },
  REGISTRATION_DISBURSEMENT_TYPE: {
    TITLE: 'Келісім-шартқа қол қою және  алу әдісін таңдаңыз:',
    CHOSEN_AMOUNT: 'Берілетін сома',
    CHOSEN_TERM: 'Шағын несие мерзімі',
    CHOSEN_INSURANCE: 'Шағын несиені сақтандыру'
  },
  MULTI_LOAN_DISBURSEMENT_TYPE: {
    TITLE: 'Обновите способ перечисления'
  },
  REGISTRATION_SUMMARY: {
    TITLE: 'Сіз таңдадыңыз'
  },
  REGISTRATION_DECISION: {
    TITLE: 'Құттықтаймыз, сіздің өтініміңіз мақұлданды!',
    SUB_TITLE: 'Өтінішіңіз өңделуде.'
  },
  REGISTRATION_OFFER: {
    INCREASE_TITLE: 'Сіздің несиелік лимитіңіз ұлғайтылды!',
    INCREASE_SUBTITLE: 'Сіз көбірек ала аласыз',
    DECREASE_TITLE: 'Біз сізге {amount} теңге сомасын бере аламыз!',
    CHOOSE_TEXT: 'Нұсқаны таңдаңыз',
    CONFIRM_BASE_BUTTON: 'Ақша алу',
    CONFIRM_INCREASE_BUTTON: 'Көбірек ақша алу'
  },
  REGISTRATION_MANUAL_VERIFICATION: {
    TITLE: 'Құттықтаймыз, сіздің өтініміңіз мақұлданды!',
    SUB_TITLE: 'Сізге 30 минут ішінде біздің қызметкеріміз хабарласады.'
  },
  REGISTRATION_APPLICATION_APPROVED: {
    TITLE: 'Құттықтаймыз, Сіздің өтінішіңіз мақұлданды!',
    SUBTITLE: 'Ақша шартқа қол қойылғаннан кейін түседі.'
  },
  REGISTRATION_APPLICATION_REJECTED: {
    TITLE: 'Өкінішке қарай, микронесиеңіз рәсімделмеді. Өтінімді серіктестеріміз арқылы жібере аласыз.',
    CONTINUE: 'Серіктеске өту'
  },
  REGISTRATION_VERIGRAM_INTRO: {
    TITLE: 'Биометрияны',
    SUB_TITLE: 'Биометрияны өту үшін басыңыз камераны қосуға рұқсат беріңіз',
    CONTINUE: 'Бастау'
  },
  PROFILE: {
    SETTINGS: 'Бейінді баптау',
    NAVIGATION: {
      [PROFILE_ROUTES.MY_LOAN_PAGE]: 'Ағымды микронесие',
      [PROFILE_ROUTES.DOCUMENTS_PAGE]: 'Құжаттар',
      [PROFILE_ROUTES.CHANGE_PASSWORD_PAGE]: 'Құпиясөзді ауыстыру',
      [PROFILE_ROUTES.CHANGE_EMAIL_PAGE]: 'Электрондық поштаны өзгерту',
      [PROFILE_ROUTES.CHANGE_PHONE_PAGE]: 'Телефон нөмерін ауыстыру',
      [PROFILE_ROUTES.CHANGE_LOCALE]: 'Сменить язык коммуникации',
      [PROFILE_ROUTES.DEBT_SETTLEMENT_LIST]: 'Берешекті реттеу',
      CREDITS: 'Менің несиелерім',
      COMMUNICATIONS: 'Менің коммуникациям',
      PAYMENTS: 'Менің төлемдерім',
      CONTACT_US: 'Бізбен хабарласу',
      REFER_FRIEND: 'Refer a friend'
    },
    LOGOUT: 'Шығу'
  },
  PROFILE_NO_LOAN: {
    JOURNEY_NOT_FINISHED: 'Микрокредит алу үшін Сіз өтінімді аяқтап, беруіңіз қажет.',
    CONTINUE: 'Жалғастыру'
  },
  PROFILE_MY_LOAN: {
    TITLE: 'Ағымды микронесие',
    NEW_LOAN: 'Жаңа микронесие',
    ADDITIONAL_LOAN_AVAILABLE: 'Сізде қосымша қолжетімді сома бар',
    ADDITIONAL_LOAN_AVAILABLE_BUTTON: 'Алу',
    AGREEMENT_NUMBER: 'Шарттың нөмірі',
    PRINCIPAL_AMOUNT: 'Берілген сома',
    ISSUED_DATE: 'Берілген күні',
    TERM: 'Микронесиенің мерзімі',
    TERM_SUFFIX: 'ай',
    TOTAL_TO_PAY_AMOUNT: 'Өтелетін жалпы сома',
    MONTHLY_PAYMENT: 'Ай сайынғы төлем',
    PAYMENT_DATE: 'Төлеу күні',
    PAYMENTS_GRAPH: 'Төлем кестесі',
    PAYMENTS_GRAPH_SHOW: 'Қарау',
    REPAY_AMOUNT: 'Өтелетін сома',
    DEBT_SETTLEMENT: 'Берешекті реттеу'
  },
  DEBT_SETTLEMENT: {
    TITLE: 'Берешекті реттеу',
    DOCUMENTS_TITLE: 'Растайтын құжаттар',
    DOCUMENTS_TOOLTIP: 'Сіздің жағдайыңызды немесе  ХӘОТ мәртебесін растау үшін (растайтын құжаттарды тіркеңіз)',
    APPLICATION_LIST: 'Шарт бойынша қарызды келісімге келтіру {agreementNumber} ({status}):',
    DOCUMENTS: 'Құжаттар',
    ADD_DOCUMENTS: 'Құжаттарды енгізу',
    APPEAL_TEXT: 'Өтініштің мазмұны',
    APPLICATION_SUBMITTED_MESSAGE: 'Сіздің өтінішіңіз  қабылданды, жауап күтуіңізді сұраймыз.',
    STATUS_TITLE: 'Өтінімнің жағдайы',
    SUBMIT_APPLICATION: 'Өтінішті жіберу',
    DOCUMENTS_PLACEHOLDER: '+ Құжаттарды жүктеу',
    STATUS: {
      [DebtSettlementApplicationStatus.ACCEPTED]: 'Өтінім мақұлданды',
      [DebtSettlementApplicationStatus.ACCEPTED_BY_POSTPONEMENT]: 'Өтінім мақұлданды',
      [DebtSettlementApplicationStatus.ACCEPTED_BY_RESCHEDULE]: 'Өтінім мақұлданды',
      [DebtSettlementApplicationStatus.ACCEPTED_BY_WRITE_OFF]: 'Өтінім мақұлданды',
      [DebtSettlementApplicationStatus.REJECTED]: 'Өтінім қабылданбады',
      [DebtSettlementApplicationStatus.CANCELLED]: 'Өтінім тоқтатылды',
      [DebtSettlementApplicationStatus.SUBMITTED]: 'Өтінім жұмысқа қабылданды',
      [DebtSettlementApplicationStatus.FIRST_REVIEWED]: 'Өтінім қарауда',
      [DebtSettlementApplicationStatus.SECOND_REVIEWED]: 'Өтінім қарауда',
      [DebtSettlementApplicationStatus.FIRST_OFFERED]: 'Ұсынысқа жауап күту',
      [DebtSettlementApplicationStatus.FIRST_OFFER_EXPIRED]: 'Ұсынысқа жауап күту',
      [DebtSettlementApplicationStatus.SECOND_OFFERED]: 'Ұсынысқа жауап күту',
      [DebtSettlementApplicationStatus.SECOND_OFFER_EXPIRED]: 'Ұсынысқа жауап күту',
      [DebtSettlementApplicationStatus.DOCUMENTS_REQUESTED]: 'Қосымша құжаттар қажет'
    }
  },
  PROFILE_INFO: {
    FIRST_NAME: 'Аты',
    LAST_NAME: 'Тегі',
    PHONE: 'Телефон нөмірі',
    EMAIL: 'Сіздің email',
    DECLARED_ADDRESS: 'Декларацияның мекенжайы',
    LOCALE: 'Язык коммуникации',
    BANK_ACCOUNT: 'Банктік шот (IBAN)',
    WORK_INFO: 'Жұмыспен қамту',
    CARD: 'Банк картасы'
  },
  PROFILE_MY_DOCUMENTS: {
    TITLE: 'Құжаттар',
    AGREEMENT_DOCUMENT_LIST: 'Шарт {agreementNumber}:',
    DEBT_SETTLEMENT_APPLICATION_LIST: 'Шарт бойынша қарызды келісімге келтіру {agreementNumber}:',
    CLIENT_DOCUMENT_LIST: 'Басқа:',
    DOCUMENTS: {
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT]: 'Микрокредит шарты (стандартты)',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_POSTPONEMENT_CONTRACT]: 'Кейінге қалдыру туралы келісім  шарт',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_POSTPONEMENT_CONTRACT_SIGNATURE]:
        'Қолыммен. Кейінге қалдыру туралы келісім  шарт',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT]: 'Микрокредит шарты',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE]: 'Қол қоюшы',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE_INFO]: 'Құжаттың ЭЦҚ бойынша ақпараты',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_SIGNATURE]: 'Қолыммен. Микрокредит шарты',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT]: 'Микрокредит шарты',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE]: 'Қол қоюшы',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE_INFO]:
        'Құжаттың ЭЦҚ бойынша ақпараты',
      [AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_SIGNATURE]: 'Қолыммен. Микрокредит шарты',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PERSONAL_DATA]: 'Дербес деректерді өңдеу туралы келісім',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_REPORT]: 'Кредиттік есепті беруге келісім',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_BUREAU_SHARE]: 'Кредит бюроларына ақпарат беруге келісім',
      [AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PROCESS_INCOME]: 'Кірістер туралы ақпарат алуға келісім',
      [AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE]: 'Клиент сауалнамасы',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_CONTRACT]: 'Микрокредит шарты (толық)',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_APPLICATION]: 'Сақтандыру туралы өтініш',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION]: 'Соманы аудару туралы өтініш',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_SIGNATURE]: 'Қолыммен. Микрокредит шарты (стандартты)',
      [AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE_SIGNATURE]: 'Қолыммен. Клиент сауалнамасы',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_CONTRACT_SIGNATURE]: 'Қолыммен. Микрокредит шарты (толық)',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_APPLICATION_SIGNATURE]: 'Қолыммен. Сақтандыру туралы өтініш',
      [AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION_SIGNATURE]: 'Қолыммен. Соманы аудару туралы өтініш',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_AMENDMENT]: 'Жаңартылған шарт',
      [AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_AMENDMENT_FULL]: 'Жаңартылған шарт',
      [OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT]: 'Берешекті реттеу өтініміне қосымша',
      [OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT_OFFER]: 'Берешекті реттеу жөніндегі ұсыныс',
      [OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT_RESCHEDULE]: 'Берешекті реттеу шарты'
    }
  },
  LOCALE_SETTINGS: {
    TITLE: 'Смена языка коммуникации'
  },
  PASSWORD_SETTINGS: {
    TITLE: 'Құпиясөзді ауыстыру'
  },
  EMAIL_SETTINGS: {
    TITLE: 'Электрондық поштаны өзгерту',
    POPUP_TITLE: 'Электрондық поштаны растау',
    POPUP_RULE: 'Сіздің көрсетілген e-mail мекен жайын растау үшін  электрондық поштаға код жіберілді',
    POPUP_SMS_CODE_SEND_AGAIN: 'Электрондық пошта кодын қайтадан жіберіңіз'
  },
  PHONE_SETTINGS: {
    TITLE: 'Телефон нөмерін ауыстыру',
    POPUP_TITLE: 'Телефонды тексеру',
    POPUP_RULE:
      'Бұл Cіздің телефон нөміріңіз екеніне көз жеткізуіміз керек. Сізбен көрсетілген нөмірге {hiddenPhoneNumber}, коды бар SMS жіберілді.',
    POPUP_SMS_CODE_SEND_AGAIN: 'SMS-кодты қайта жіберу'
  },
  CONSENTS: {
    PERSONAL_DATA_PROCESSING: 'Дербес деректерді жинауға және өңдеуге келісім',
    CREDIT_REPORT: 'Несие тарихы субъектісінің несиелік есептеме алушысына несиелік есептеме беру туралы келісім',
    MICRO_CREDIT_INFO: 'Несие тарихы субъектісінің ол туралы несие бюросына ақпаратты ұсыну жайындағы келісім',
    INCOME_OUTCOME_PROCESSING: 'Мен шетелдік мемлекеттік қызметкер емес екенімді растаймын',
    NOT_FOREIGN_GOVERNMENT_OFFICIAL: 'Мен шетелдік мемлекеттік қызметкер емес екенімді растаймын'
  },
  CREDIT_FOR_BUSINESS: {
    TITLE: 'Қазақстандағы микронесие арналған',
    SUB_TITLE: 'шағын бизнеске',
    TEXT_1:
      'Қарыз қаражатын алу шағын бизнестің иелеріне экономикалық өзгерістерге уақытылы назар аударып, өзінің ісін дамытуға мүмкіндік береді. Көбінесе осы шағын кәсіпорындар қосымша айналым қаражатына мұқтаж. Ал банктер оларға бизнесті дамыту үшін несиені зауықсыз береді, ал несиелеудің бағасы шағын кәсіпкерілік субъектілері үшін бұрынғыдай өте жоғары болып келеді.',
    TEXT_2:
      'Банктердің көпшілігінде шағын бизнесті несиелеу бағдарламасы кепілдің бар болуын көздейді, ал кепілсіз несиелер белгілі сомамен шектелген, жоғары пайызға рәсімделеді және кепілгерлердің болуын талап етеді. Оның үстіне бизнесті басқару – үнемі қатысу мен шаралардың барлығына өзің қатысуды талап ететін өмір салты. Бос уақыттың жоқтығы – шағын бизнес иелерінің банктен несие алуға асықпайтынының негізгі себептерінің бірі.',
    BENEFITS: {
      HEADING: 'Шағын бизнес иелері үшін Salem.kz сайтында онлайн-несиелеудің артықшылығы неде?',
      SPEED: {
        TITLE: 'Жылдамдық.',
        DESC:
          'Өтінімді сайтта жай рәсімдеу мен клиенттердің төлемге қабілеттілігін бағалаудың жылдамдатылған жүйесі керекті ақшаны барынша жылдам алуға мүмкіндік береді. Salem.kz сайтында микронесиені анықтамасыз, кепілгерсіз, кепілсіз және банктің шешімін ұзақ күтусіз алуға болады. Ақша өтінім мақұлданғаннан кейін 15 минут ішінде банктік картаға жіберіледі.'
      },
      REMOTE: {
        TITLE: 'Дистанциондылық.',
        DESC:
          'Несиені рәсімдеу және алу үшін кеңсеге барудың қажеті жоқ, бұл әсіресе әрбір минуты есептеулі және кезекте тұратын уақыты жоқ бау-бақша шаруашылығының иелері үшін ыңғайлы. Өтінім компанияның сайтында рәсімделеді және өтінім мақұлданғаннан кейін ақша клиенттің картасына бірден түседі.'
      },
      TRUST: {
        TITLE: 'Сенім',
        DESC:
          'Банктен несие алу үшін ликвидтік кепіл (жаңа автокөлік, жылжымайтын мүлік т.с.с.) ұсыну немесе кепілгердің қолдауын растау керек. Salem.kz сайтында микронесие рәсімдеу кепілді және кепілгерді талап етпейді.'
      },
      CREDIT_HISTORY: {
        TITLE: 'Несие тарихын жақсарту',
        DESC:
          'Кейбір кәсіпкерлер ірі тұтынушылық несие туралы ойлайды, бірақ банк оны беретініне күмәнданады. Микронесиені онлайн рәсімдей отырып, ал бастысы – оны уақытылы төлей отырып, жақын мақсаттар үшін ақша алып қана қоймай, сонымен қатар несиелік тарихты жақсартуға болады, нәтижесінде банктер үшін айтарлықтай қалаулы клиентке айналу және ірі тұтынушылық несие алу мүмкіндігі айтарлықтар артады.'
      },
      CONFIDENTIALITY: {
        TITLE: 'Құпиялылық',
        DESC:
          'Таныстардан немесе туыстардан қарызға ақша алғанда қарыз не үшін керектігін, неге «қауіпсіздік жастығының» жоқ екендігін түсіндіру және жоспарлаудың пайдасы туралы ақыл-кеңестер тыңдау керек. Ал Salem.kz сайтында өтінім рәсімдеп, сіз ешкімге ештеңе түсіндірмей, ақталмай, қажетті соманы көрсетесіз.'
      },
      FINANCIAL_DISCIPLINE: {
        TITLE: 'Қаржылық тәртіпті дамыту',
        DESC:
          'Salem.kz сайтында микронесие рәсімдеу кезінде сізге мүлтіксіз орындау керек және оған сіз уақыт өте келе үйреніп кететін қарызды өтеу кестесі ұсынылады. Микронесиені төлеп болғаннан кейін сіз дәл соманы ай сайын қиналмай, бизнесті ары қарай дамыту немесе басқа мақсаттар үшін жинай бересіз.'
      }
    }
  },
  HOW_TO_RETURN: {
    TITLE: 'Қалай қайтаруға болады',
    NOTE:
      'Микрокредитті уақытылы өтеген жағдайда, Сіз өзіңіздің несие тарихыңызды жақсартасыз және өзіңіздің несие лимитіңізді арттыру мүмкіндігін арттырасыз. Сіз төлемді келесі тәсілдердің бірімен өтей аласыз:',
    METHODS: {
      ONLINE_BANKING: 'онлайн-аударым (банктің мобильдік қосымшасындағы заңды тұлғаның шотына)',
      KASSA_24_TERMINAL: 'КАССА 24 терминалы арқылы өтеу',
      CARD_PAYMENT: 'онлайн-төлем (жеке кабинеттен төлем жасау)',
      QIWI_TERMINAL: 'Qiwi төлем терминалдары арқылы',
      QIWI_WALLET: 'онлайн аударма Qiwi- кошелек арқылы ',
      PAYMENT_BANK_DESK: 'кез келген банктің кассасы арқылы'
    },
    END_NOTE:
      '<b>Salem.kz қызметті жақсарту бойынша белсенді жұмыс істейді, сондықтан жақын арада қосымша төлем әдістері қосылады!</b>',
    DIFFERENT_PAYMENT_TYPE: 'Басқа төлем әдісін таңдаңыз'
  },
  RETURN_BY_ONLINE_BANKING: {
    TITLE: 'Онлайн аударма',
    NOTE:
      'Микрокредитті заңды тұлғалардың шоттарына онлайн-аударымдарды жүзеге асыруға мүмкіндік беретін банктің мобильді қосымшасы арқылы өтеуге болады, банкке және оның мобильді қосымшасына байланысты төлем тәртібі аздап өзгеруі мүмкін.',
    OPTION_TITLE:
      '<b>Төменде "Халық Банк" мобильдік қосымшасы арқылы онлайн-аударымды қалай жүргізу керектігі туралы қадамдық нұсқаулық берілген.<b>',
    STEPS: {
      STEP1: 'Halyk Bank мобильді қосымшасында "аударымдар" - "барлық аударымдар" қосымша беті бойынша өту қажет.',
      STEP2: '<b>"Заңды тұлғалар шотының нөміріне"</b> таңдау',
      STEP3:
        'Salem шотының нөмірін енгізу (Kz226018771000900151), БСН 200540006761, резидентті таңдау және "Далее" батырмасын басу',
      STEP4:
        'БСН дұрыс енгізген кезде Компанияның толық атауы көрсетілуі тиіс. "КБе (алушының коды)" жолында  "5 - басқа қаржы ұйымдарын" таңдау қажет, "ТТК (төлем мақсатының коды)" жолында  "421 - қысқа мерзімді қарыздарды өтеу" (12 айға дейін) не "423 - ұзақ мерзімді қарыздарды өтеу" (12 айдан астам) таңдау қажет. МАҢЫЗДЫ! Аударымды тағайындау жолында шарттың нөмірін және ЖСН/ТАӘ көрсету қажет. Аударма шарттарымен келісуді көрсету және "Далее"  батырмасын басу қажет.   ',
      STEP5:
        'Өз картаңызды "Қайдан" деген жолда өз картаңызды таңдаңыз, "Қанша" деген жолда өтелетін соманы көрсету және "Бұдан әрі" батырмасын басу керек.',
      STEP6:
        'Төлемнің соңғы қадамында "Растау" батырмасын басыңыз және картада жеткілікті қаражат болған жағдайда төлем сәтті жүзеге асырылады'
    }
  },
  RETURN_BY_KASSA_24_TERMINAL: {
    TITLE: 'КАССА 24 терминалы арқылы өтеу',
    STEPS: {
      STEP1: 'Ең жақын Kassa 24 Терминалын табыңыз',
      STEP2: 'Одан кейін <b>"Қаржы ұйымдары"</b> бөлімін таңдаңыз',
      STEP3: '<b>"Қызметті таңдаңыз"</b> бөлікшесінде <b>"МКО және МФО"</b> таңдаңыз',
      STEP4: 'Кейін <b>"Salem.kz"</b> табыңыз',
      STEP5: 'Өз ЖСН енгізіңіз және <b>"Жалғастыру"</b> басыңыз',
      STEP6:
        'Терминалға қажетті соманы салыңыз да, <b>"Төлеу"</b> басыңыз. МАҢЫЗДЫ! Төлем үшін <b>1.3%</b> комиссия алынады.'
    }
  },
  ONLINE_SIGNATURE: {
    AGREEMENT: 'Шарт',
    DOCUMENT_OPEN: 'Ашу',
    DOCUMENT_DOWNLOAD: 'Жүктеу'
  },
  RETURN_BY_CARD_PAYMENT: {
    TITLE: 'Жеке кабинеттен төлем жасау',
    STEPS: {
      STEP1: 'Жеке кабинетке кіру қажет',
      STEP2: '<b>"Ағымды микронесие"</b> бөлімін ашу қажет, одан соң жаңа төлем картасын енгізу керек',
      STEP3: 'Картаны енгізген соң, қажет соманы көрсетіп, төлем жасау керек',
      STEP4: 'Төлемді растау қажет',
      STEP5: 'Төлем сәтті өтсе, төлем бойынша хабарлама шыгады. <b>МАҢЫЗДЫ!</b> Төлем үшін <b>3.7%</b> комиссия алынады'
    }
  },
  RETURN_BY_QIWI_TERMINAL: {
    TITLE: 'QIWI терминал арқылы төлеу',
    STEPS: {
      STEP1: 'Ең жақын QIWI терминалын табыңыз. Терминалда "Қызметтерді төлеу" бөліміне кіру қажет',
      STEP2: 'Одан кейін «Несиелерді өтеу/қаржы қызметтері» бөлімін таңдаңыз',
      STEP3: '«Несиелерді өтеу» бөлікшесінде «МКО және МФО» таңдаңыз',
      STEP4: '«Salem.kz» батырмасын тауып, басыңыз.',
      STEP5: 'Өрісте өз ЖСН нөмірін енгізіп, "Алға" батырмасын басыңыз',
      STEP6:
        'Терминалға өзіңізге қажетті ақша соманы  енгізіп, <b>"Алға"</b> батырмасын басыңыз. МАҢЫЗДЫ! Әдепкі төлем сомасында ағымдағы төлем кезеңінің төлем сомасы, сондай-ақ мерзімі өткен берешек сомасы (бар болса) көрсетіледі.',
      STEP7: 'Төлемнің соңғы қадамында <b>"Төлеу"</b> түймесін басыңыз'
    }
  },
  RETURN_BY_QIWI_WALLET: {
    TITLE: 'QIWI-кошелек арқылы төлеу',
    STEPS: {
      STEP1: 'QIWI кошелекті ашыңыз',
      STEP2: '<b>"Іздеу"</b> жолына Salem.kz  енгізіңіз және ашылмалы тізімнен таңдаңыз',
      STEP3: 'Сәйкес өрістерде ЖСН мен төленетін соманы көрсетіңіз',
      STEP4: ' <b>"Төлеу" (комиссия 2%)</b> түймесін басыңыз'
    }
  },
  RETURN_BY_PAYMENT_BANK_DESK: {
    TITLE: 'Банк кассасы арқылы төлеу',
    NOTE:
      'Микрокредитті өтеуді заңды тұлғалардың шоттарына ақша қаражатын қабылдауды және аударуды жүзеге асыратын кез келген банктің кассасы арқылы жүргізуге болады, касса арқылы өтеу үшін Сізге тек өз деректеріңізді (ЖСН, ТАӘ) және Salem.kz  деректерін  кассирге беру қажет:',
    STEPS: {
      STEP1: '"Қазақстан Халық Банк" АҚ -дағы Salem.kz банктік шоты <b>No KZ226018771000900151</b>',
      STEP2: 'БСН Salem.kz <b>200540006761</b>',
      STEP3:
        'Кбе (алушының коды) <b>"5"</b> немесе <b>"7"</b>, ТТК (төлем белгілеу коды) - <b>"421"</b> (12 айдан аспайтын мерзімдегі микрокредиттер бойынша) немесе <b>"423"</b> (12 айдан астам мерзімдегі микрокредиттер бойынша)',
      STEP4:
        'Өзіңнің <b>микрокредит шартыңның нөмірі</b>. МАҢЫЗДЫ! Кассирден төлем мақсатында өзіңіз төлем жасап отырған микрокредит шартының нөмірін көрсетуін сұраңыз.'
    }
  },
  CONTACTS: {
    TITLE: 'Байланыстар',
    SECTION_MAIN: {
      TITLE: 'Клиенттерге қамқор көрсету қызметі',
      PHONE: phoneNumber,
      EMAIL: 'info@salem.kz',
      ADDRESS: 'Қазақстан Республикасы, 050012, Алматы қ., Алмалы ауданы, Сейфуллин даңғылы, № 502 үй, 8 қабат'
    },
    SECTION_WORK_HOURS: {
      TITLE: 'Жұмыс уақыты:',
      WORK_DAYS: 'Дүйсенбі-Жексенбі',
      WORK_HOURS: '9:00-ден бастап 18:00 дейін'
    }
  },
  FORM: {
    FIELD_SLIDER_AMOUNT_LABEL: 'Шағын несие сомасы',

    FIELD_SLIDER_TERM_LABEL: 'Шағын несие мерзімі',
    FIELD_SLIDER_TERM_MONTH_SUFFIX: 'ай',
    FIELD_SLIDER_TERM_TOOLTIP: 'Микрокредит беру мерзімі сомасына байланысты',

    FIELD_PRODUCT_INSURANCE_LABEL: 'Қарызгерді сақтандыру',
    FIELD_PRODUCT_INSURANCE_INFO:
      'микронесие бойынша жылдық 4,8% бастап сақтандыру + қарызгерді «Freedom Finance Life» ӨСК» АҚ берілген микронесиенің әрекет ету мерзіміне жазатайым оқиғадан онлайн-сақтандыру. Сақтандыру бағасы  {insuranceCost}, әрекет ету мерзімі – {validity} ж. дейін, сақтандыру орнын төлеу – {totalPrincipal} теңге. Қарызгерді жазатайым оқиғадан онлайн-сақтандыру декларациясы <a href="/docs/life-declaration.docx" download="ДЕКЛАРАЦИИ ПО ОНЛАЙН-СТРАХОВАНИЮ ЗАЁМЩИКА ОТ НЕСЧАСТНОГО СЛУЧАЯ.docx"><i class="el-icon-download"></i></a>. Мен Қарызгерді жазатайым оқиғадан ерікті онлайн-сақтандыру шартымен таныстым және келісемін және «Salem Credit» МҚҰ» ЖШС-нен мен үшін сақтандыру бағасын ұсынылатын микронесиенің шотына енгізуді сұраймын.',
    FIELD_PRODUCT_REGULAR_LABEL: 'Сақтандырусыз',
    FIELD_PRODUCT_REGULAR_INFO:
      'Микронесие бойынша жылдық 54,4%, қарызгерді сақтандыру қолжетімсіз, мақұлдау мүмкіндігі төмен.',

    [FORM_ERRORS.FIELD_ERROR_DEFAULT]: 'Өтініш дұрыс болмады. Кейінірек көріңіз',
    [FORM_ERRORS.FIELD_ERROR_AMOUNT_LIMIT]: 'Жаңа клиенттер үшін максималды қолжетімді сома - 1 000 000 теңге',
    [FORM_ERRORS.FIELD_ERROR_AVAILABLE_CREDIT]:
      'Сіз сұраған {loanAmount} сома, сіздің қол жетімді лимиттен асады {availableCredit}',
    [FORM_ERRORS.FIELD_ERROR_MAX_PAYMENT]:
      'Сұралған ай сайынғы төлеміңіз {paymentAmount}  қолжетімді лимиттен {maxPaymentAmount} асып кетті',
    [FORM_ERRORS.FIELD_ERROR_NON_FINAL_APPLICATION_STATE]: 'Сізде аяқталмаған өтініштер бар.',
    [FORM_ERRORS.FIELD_ERROR_NON_FINAL_AGREEMENT_STATE]: 'Сіздің өтелмеген несиеңіз бар',
    [FORM_ERRORS.FIELD_ERROR_REQUIRED]: 'Бұл өріс міндетті',
    [FORM_ERRORS.FIELD_ERROR_ONLY_NUMBERS]: 'Бұл өріс тек сандарды қабылдайды',
    [FORM_ERRORS.FIELD_ERROR_EMAIL_REGEX]: 'Электрондық пошта пішімі жарамсыз',
    [FORM_ERRORS.FIELD_ERROR_PHONE_NUMBER_FORMAT]: 'Телефон нөмірінің форматы жарамсыз',
    [FORM_ERRORS.FIELD_ERROR_ID_NUMBER_FORMAT]: 'ЖСН енгізіңіз (жеке куәліктен 12 сан)',
    [FORM_ERRORS.FIELD_ERROR_ID_NUMBER_NOT_EXIST]: 'ЖСН немесе пароль қате енгізілді. Қайта кіріп көріңіз.',
    [FORM_ERRORS.FIELD_ERROR_CLIENT_IS_BLOCKED]: 'Құпия сөзді сұрау шегінен асып кетті, {helpLink}',
    [FORM_ERRORS.HELP_LINK_TEXT]: 'бізге хабарласыңыз',
    [FORM_ERRORS.FIELD_ERROR_PASSWORD_FORMAT]: 'Құпиясөз 8 символдан кем болмауы қажет',
    [FORM_ERRORS.FIELD_ERROR_PASSPORT_ID]: 'Жеке куәлік нөмірінің ұзындығы жарамсыз. Сан 9 саннан тұруы керек',
    [FORM_ERRORS.FIELD_ERROR_DATE_FORMAT]: 'Өтінеміз, дұрыс күн пішімін қолданыңыз: КК/АА/ЖЖЖЖ',
    [FORM_ERRORS.FIELD_ERROR_DATE]: 'Жарамсыз күн',
    [FORM_ERRORS.FIELD_ERROR_DATE_IS_PAST]: 'Дата не может быть в прошлом',
    [FORM_ERRORS.FIELD_ERROR_DATE_IS_FUTURE]: 'Дата не может быть в будущем',
    [FORM_ERRORS.FIELD_ERROR_IS_CLIENT_PHONE_NUMBER]: 'Бұл телефон нөмірі salem.kz тіркелген Кіру',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MIN_FOUR]: 'Шығыстар сомасы 4 және одан да көп сандардан тұруы тиіс',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MIN_FIVE]: 'Кіріс сомасы 5 және одан да көп сандардан тұруы тиіс',
    [FORM_ERRORS.FIELD_ERROR_FINANCE_MAX_ADDITIONAL_INCOME]: 'Максималды қолжетімді сома - 10 000 000 теңге.',
    [FORM_ERRORS.FIELD_ERROR_BANK_ACCOUNT_NUMBER_FORMAT]:
      'IBAN шоты жарамсыз. IBAN - бұл KZXX XXXXX XXXXX XXXXX XXXX форматындағы банктік шот нөмірі. Сіз мұны банктен, сондай-ақ Интернет-банкингтен біле аласыз.',
    [FORM_ERRORS.FIELD_ERROR_BANK_CARD_NOT_VERIFIED]: 'Банк картасы тексеруден өткен жоқ',
    [FORM_ERRORS.FIELD_ERROR_BANK_CARD_VERIFICATION_EXPIRED]: 'Банк картасын тексеру  ескірген, қайта өту қажет',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_400]: 'Банк картасы тексеруден өтті',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_409]: 'Соманы тексеру сәтсіз аяқталды',
    [FORM_ERRORS.FIELD_ERROR_CONFIRM_AMOUNT_410]: 'Тексеру уақыты аяқталды',

    [FORM_ERRORS.FIELD_ERROR_WRONG_AGE]: 'Кешіріңіз, біз 18 жастан 68 жасқа дейінгі клиенттерге микрокредит береміз',
    [FORM_ERRORS.FIELD_ERROR_NO_LETTER]: 'Көше атын енгізіңіз',
    [FORM_ERRORS.FIELD_ERROR_USERNAME_NOT_UNIQUE]: 'Бұл ЖСН salem.kz тіркелген. {login}',
    [FORM_ERRORS.FIELD_ERROR_EMAIL_NOT_UNIQUE]: 'Бұл электронды пошта salem.kz тіркелген {login}',
    [FORM_ERRORS.FIELD_ERROR_PHONE_NOT_UNIQUE]: 'Бұл телефон нөмірі salem.kz тіркелген {login}',
    [FORM_ERRORS.FIELD_ERROR_WRONG_VERIFICATION_CODE]: 'Жарамсыз код енгізілді',
    [FORM_ERRORS.FIELD_ERROR_VERIFICATION_RESTORE_FAILED]: 'Тексеруді қалпына келтіру мүмкін емес',
    [FORM_ERRORS.FIELD_ERROR_VERIFICATION_CREATE]: 'Тексеруді бастау мүмкін болмады',

    [FORM_ERRORS.FIELD_ERROR_INVALID_IIN]: 'ЖСН мәні қате емес',
    [FORM_ERRORS.FIELD_ERROR_INVALID_IBAN]: 'Бұл банктік шот нөмірі (IBAN) жарамсыз',
    [FORM_ERRORS.FIELD_ERROR_NEW_PASSWORD_SAME_AS_OLD]: 'Жаңа құпия сөз ағымдағы сәйкес келе алмайды',
    [FORM_ERRORS.FIELD_ERROR_PASSWORD_IS_NOT_SAME]: 'Құпия сөз сәйкес келуі керек',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CURRENT_PASSWORD]: 'Құпия сөз дұрыс емес',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_EMAIL]: 'Қате пошта немесе телефон нөмері',
    [FORM_ERRORS.FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_PHONE]: 'Қате пошта немесе телефон нөмері',
    [FORM_ERRORS.FIELD_ERROR_INVALID_PHONE_NUMBER]: 'Қате пошта немесе телефон нөмері',
    [FORM_ERRORS.FIELD_ERROR_SMALL_LENGTH]: 'Мәтін тым қысқа. Толығырақ жазыңыз.',
    [FORM_ERRORS.FIELD_ERROR_BIG_LENGTH]: 'Мәтін тым ұзын.',
    [FORM_ERRORS.COID_VERIFICATION_FAILED]: 'Тексеру сәтсіз аяқталды',
    [FORM_ERRORS.BANK_STATEMENT_END_DATE_BEFORE_START_DATE]: 'Тексеру сәтсіз аяқталды',
    [FORM_ERRORS.BANK_STATEMENT_FILES_REQUIRED]: 'Мәлімдемені жүктеңіз',
    [FORM_ERRORS.BANK_STATEMENT_LIMIT_REACHED]: 'Достигнут лимит количества файлов',
    [FORM_ERRORS.BANK_STATEMENT_UPLOAD_ERROR]: 'Жүктеу қатесі',
    [FORM_ERRORS.FIELD_ERROR_FILE_TYPE]: 'Пішімі жарамсыз. Рұқсат етілген пішімдері jpg, png, jpeg, pdf',
    [FORM_ERRORS.BANK_STATEMENT_FILE_SIZE]:
      'Бастапқы кескіннің өлшемі қажетті форматқа сәйкес келмейді, 20 МБ аспайтын файлды жүктеп алыңыз',

    ERROR_NOTIFICATION_TITLE: 'Қате!',
    ERROR_APPLICATION_REJECTED_429:
      'Қазіргі уақытта біз сізге шағын несие бере алмаймыз. Сіз {dateTime} кейін немесе www.koke.kz сайтында өтініш бере аласыз.',

    SUBMIT_BUTTON: 'Келесі қадам',
    CONFIRM_BUTTON: 'Растау',
    SUBMIT_APPLICATION: 'Өтініш беру',
    DECLINE_BUTTON: 'Бас тарту',
    SUBMIT_VERIFICATION: 'Растау',
    UPLOAD_FILES: 'Файлдарды жүктеу',
    SAVE: 'Сақтау',

    FIELDS: {
      locale: {
        TITLE: 'Язык коммуникации',
        TOOLTIP: 'Выберите желаемый язык для общения с нами'
      },
      username: {
        TITLE: 'Сіздің ЖСН',
        TOOLTIP: 'ЖСН енгізіңіз (жеке куәліктен 12 сан)'
      },
      email: {
        TITLE: 'Сіздің email',
        TOOLTIP:
          'Электрондық поштаңы енгізіңіз. Көрсетілген электрондық поштаңызға жеке кабинетке кіру үшін пароль, микрокредит бойынша құжаттар мен хабарламалар жіберіледі.'
      },
      mobilePhone: {
        TITLE: 'Телефон нөмірі',
        TOOLTIP: 'Ұялы телефон нөмірін көрсетіңіз - ол жеке кабинетке кіру және сізбен байланыс үшін пайдаланылады.'
      },
      contactPhone: {
        TITLE: 'Телефон нөмірі',
        TOOLTIP: 'Өтініш сұрақтары бойынша хабарласуға болатын телефон нөмірін көрсетіңіз.'
      },
      appealText: {
        TITLE: 'Хабарлама мәтіні',
        TOOLTIP: 'берешекті кешіктірудің себебін көрсетіңіз'
      },
      code: {
        TITLE: 'SMS-код',
        TOOLTIP: 'Ұялы телефонға жіберілген 6 санды кодты енгізіңіз.'
      },
      newEmail: {
        TITLE: 'Жаңа электрондық пошта'
      },
      identifierEmail: {
        TITLE: 'Эл.пошта'
      },
      identifierPhone: {
        TITLE: 'Телефон нөмірі'
      },
      identifier: {
        TITLE: 'Телефон немесе e-mail'
      },
      passwordResetCode: {
        TITLE: 'Растау коды'
      },
      emailCode: {
        TITLE: 'Код',
        TOOLTIP: 'Көрсетілген электрондық поштаға жіберілген 6 таңбалы кодты енгізіңіз.'
      },
      password: {
        TITLE: 'Сіздің пароліңіз'
      },
      oldPassword: {
        TITLE: 'Бұрынғы құпиясөз'
      },
      newPassword: {
        TITLE: 'Жаңа құпиясөз'
      },
      repeatNewPassword: {
        TITLE: 'Құпиясөзді қайталаңыз'
      },
      testAmount: {
        TITLE: 'Үзінді көшірме сомасы',
        TOOLTIP: 'Көрсетілген банктік карта шотының үзінді көшірмесін тексеріңіз және соманы енгізіңіз'
      },
      personalInfo: {
        name: {
          firstName: {
            TITLE: 'Аты',
            TOOLTIP: 'Атын енгізіңіз'
          },
          lastName: {
            TITLE: 'Тегі',
            TOOLTIP: 'Тегіңізді енгізіңіз'
          },
          middleName: {
            TITLE: 'Әкесінің аты',
            TOOLTIP: 'Атыңызды енгізіңіз'
          }
        },
        birthDate: {
          TITLE: 'Туған күні',
          PLACEHOLDER: 'KK/ АЙ / ЖЖЖЖ',
          TOOLTIP: 'Туған күніңізді енгізіңіз'
        },
        gender: {
          TITLE: 'Жынысы',
          TOOLTIP: 'Жынысыңызды енгізіңіз'
        }
      },
      identityDocuments: {
        number: {
          TITLE: 'Жеке куәліктің нөмірі',
          TOOLTIP: 'Жеке куәлік нөміріңізді енгізіңіз'
        },
        issuedAt: {
          TITLE: 'Берілген күні',
          PLACEHOLDER: 'KK/ АЙ / ЖЖЖЖ',
          TOOLTIP: 'Шығарылған күнін енгізіңіз'
        },
        expiresAt: {
          TITLE: 'Қолданылу мерзімі',
          PLACEHOLDER: 'KK/ АЙ / ЖЖЖЖ',
          TOOLTIP: 'Колданулы мерзімін енгізіңіз'
        },
        issuedBy: {
          TITLE: 'Беру органы',
          TOOLTIP: 'Берген орган енгізіңіз'
        }
      },
      declared: {
        fields: {
          region: { TITLE: 'Облысы', TOOLTIP: 'Тұратын жеріңізді таңдаңыз' },
          house: { TITLE: 'Үй нөмірі', TOOLTIP: 'Үй нөмірін енгізіңіз' },
          apartment: {
            TITLE: 'Пәтер нөмірі',
            TOOLTIP: 'Пәтер нөмірін көрсетіңіз. Егер сіз жеке үйде тұрсаңыз, өрісті бос қалдырыңыз.'
          }
        },
        city: {
          TITLE: 'Қала / елді мекен',
          TOOLTIP: 'Қаланы таңдаңыз'
        },
        street: { TITLE: 'Көше', TOOLTIP: 'Көше атын енгізіңіз' }
      },
      living: {
        fields: {
          region: { TITLE: 'Облысы', TOOLTIP: 'Тұратын жеріңізді таңдаңыз' },
          house: { TITLE: 'Үй нөмірі', TOOLTIP: 'Үй нөмірін енгізіңіз' },
          apartment: {
            TITLE: 'Пәтер нөмірі',
            TOOLTIP: 'Пәтер нөмірін көрсетіңіз. Егер сіз жеке үйде тұрсаңыз, өрісті бос қалдырыңыз.'
          }
        },
        city: {
          TITLE: 'Қала / елді мекен',
          TOOLTIP: 'Қаланы таңдаңыз'
        },
        street: { TITLE: 'Көше', TOOLTIP: 'Көше атын енгізіңіз' }
      },
      matchesDeclaredAddress: {
        TITLE: 'Менің нақты тұрғылықты мекен-жайым тіркеумен сәйкес келеді'
      },
      data: {
        [PROFILE_ADDITIONAL_INFO_KEYS.EDUCATION]: {
          stage: {
            TITLE: 'Білім',
            TOOLTIP: 'Білімділік дәрежесін көрсетіңіз'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO]: {
          employmentStatus: {
            TITLE: 'Жұмыспен қамту',
            TOOLTIP: 'Жұмысыңызды көрсетіңіз'
          },
          companyName: {
            TITLE: 'ұйымның атауы',
            TOOLTIP: 'ұйымның аты енгізіңіз'
          },
          industry: {
            TITLE: 'Қызмет түрі',
            TOOLTIP: 'Егер тізімде Сіздің қызмет салаңыз көрсетілмеген болса, онда «Басқаны» көрсетіңіз.'
          },
          position: {
            TITLE: 'Позициясы',
            TOOLTIP: 'Позициясының көрсетіңіз'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.BANK_STATEMENT]: {
          startDate: {
            TITLE: 'Мәлімдемеің басталу күні',
            PLACEHOLDER: 'ДД / ММ / ГГГГ',
            TOOLTIP: 'Мәлімдеменің басталу күнін енгізіңіз'
          },
          endDate: {
            TITLE: ' Мерзімнің өту күні',
            PLACEHOLDER: 'ДД / ММ / ГГГГ',
            TOOLTIP: 'Жарамдылық мерзімін енгізіңіз'
          },
          turnover: {
            TITLE: 'Жалпы тауар айналым',
            TOOLTIP: 'Айналымдардың жалпы санын енгізіңіз'
          }
        },
        [PROFILE_ADDITIONAL_INFO_KEYS.FAMILY]: {
          maritalStatus: {
            TITLE: 'Отбасылық жағдайы',
            TOOLTIP: 'Отбасылық жағдайыңызды көрсетіңіз.'
          },
          numberOfChildren: {
            TITLE: 'Балалар саны (18 жасқа дейін)',
            TOOLTIP: '18 жасқа толмаған балалардың санын көрсетіңіз'
          },
          additionalContactName: {
            TITLE: 'Қосымша байланыс нөмірі',
            TOOLTIP: 'Қосымша байланыс тұлғасының нөмірін көрсетіңіз (туыс, дос, әріптес және т.б.)'
          }
        }
      },
      purpose: {
        TITLE: 'Шағын несие мақсаты',
        TOOLTIP: 'Шағын несие мақсаты'
      },
      input: {
        metadata: {
          paymentDate: {
            TITLE: 'Төлем күні'
          }
        }
      },
      disbursement: {
        type: {
          TITLE: 'Аудару әдісін таңдаңыз:',
          TOOLTIP: 'Аудару әдісін таңдаңыз мақсаты'
        },
        data: {
          bankAccountNumber: {
            TITLE: 'Банктік шот (IBAN)',
            TOOLTIP:
              'IBAN шоты жарамсыз. IBAN - бұл KZXX XXXXX XXXXX XXXXX XXXX форматындағы банктік шот нөмірі. Сіз мұны банктен, сондай-ақ Интернет-банкингтен біле аласыз.'
          },
          bankCardNumber: {
            TITLE: 'Банк картасы (Visa, Master...)',

            ATTACH: {
              LABEL: 'Бастау үшін басыңыз:',
              BUTTON: 'Картаны тіркеу',
              HELPER:
                'Сіздің картаңызда кездейсоқ **сома** бұғатталады (100 теңгеге дейін). Бұғатталған **соманың** мөлшері картаны **растау үшін енгізуге қажетті код** болып табылады',
              FEE: '* Картаға аудару комиссиясы 1,25%'
            },
            VERIFY: {
              LABEL: 'Карта деректерін растау қажет',
              BUTTON: 'Тексеру'
            },
            REMOVE: {
              LABEL: 'Сіздің карта нөміріңіз тексерілуде',
              BUTTON: 'Болдырмау',
              CONFIRM_TITLE: 'Картаны тексеруді болдырмау',
              CONFIRM_TEXT: 'Сіз бұл картаны тексеруден бас тартқыңыз келетініне сенімдісіз бе??',
              CONFIRM_SUBMIT: 'Растаймын',
              CONFIRM_CANCEL: 'Қалдыру'
            },
            CANCEL: {
              BUTTON: 'Болдырмау'
            },
            CONFIRMED: {
              LABEL: 'Сіздің картаңыз тексеруден өтті',
              BUTTON: 'Өшіру'
            },
            EXPIRED: {
              LABEL: 'Тексеру уақыты аяқталды',
              BUTTON: 'Басынан бастау'
            }
          }
        }
      },
      signingAgreement: {
        type: {
          TITLE: 'Құжаттарға қол қою тәсілі',
          TOOLTIP: 'Құжаттарға қол қою тәсілін таңдаңыз'
        }
      }
    },

    OPTIONS: {
      gender: {
        [GENDER.MALE]: 'Еркек',
        [GENDER.FEMALE]: 'Әйел'
      },
      disbursementType: {
        [DISBURSEMENT_TYPE.BANK_ACCOUNT]: 'Банктік шот (IBAN)',
        [DISBURSEMENT_TYPE.BANK_CARD]: 'Банк картасы (Visa, Master...)'
      }
    },

    SECTIONS: {
      'personal-data': 'Жеке мәліметтер',
      address: 'Тіркеу мекенжайы',
      finances: 'Жұмыс',
      'additional-info': 'Қосымша ақпарат'
    }
  },
  SCHEDULE: {
    PAYMENT_MONTHLY: 'Ай сайынғы төлем',
    PAYMENT_GRAPHIC: 'Төлем кестесі',
    PAYMENT_GRAPHIC_DATE: 'Төлем күні',
    PAYMENT_GRAPHIC_PRINCIPAL: 'основная сумма',
    PAYMENT_GRAPHIC_INTEREST: 'Пайыздар',
    PAYMENT_GRAPHIC_AMOUNT: 'Сумма оплаты',
    SUBMIT_VERIFICATION: 'Растау'
  },
  DOCUMENTS: {
    REGISTRATION_FEE_NOTICE: 'Тіркеу туралы хабарлама 08.07.2020',
    PERSONAL_DATA_COLLECT_CONSENT: 'Дербес деректерді жинауға жəне өңдеуге келісім',
    PERSONAL_DATA_PROCESS_CONSENT: '«Салем Кредит» Шағын қаржы ұйымы» ЖШС дербес деректерді өңдеуге қатысты саясаты',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_15_08_2022:
      'Жазатайым оқиғадан ерікті онлайн сақтандыру ережелері (15.04.2024 жылғы өзгерістер мен толықтырулармен)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_19_08_2024:
      'Жазатайым оқиғадан ерікті онлайн сақтандыру ережелері (19.08.2024 жылғы өзгерістер мен толықтырулармен)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE_15_08_2022:
      'Өмірді ерікті онлайн түрде сақтандыру ережесі (15.04.2024 жылы берілген өзгерістер мен толықтырулармен)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE_19_08_2024:
      'Өмірді ерікті онлайн түрде сақтандыру ережесі (18.08.2024 жылы берілген өзгерістер мен толықтырулармен)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_LAST:
      'Қарыз алушыны жазатайым оқиғадан ерікті онлайн сақтандыру ережесі 05.03.2021 бастап (02.05.2023 өзгертулерімен)',
    VOLUNTARY_ONLINE_ACCIDENT_INSURANCE:
      'Қарыз алушыны жазатайым оқиғадан ерікті онлайн сақтандыру ережесі 05.03.2021 бастап (01.09.2022 өзгертулерімен)',
    VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE:
      'Қарыз алушының өмірін ерікті онлайн түрде сақтандыру ережесі 27.07.2020 бастап (12.10.2020 өзгертулерімен)',
    RULES_MICROCREDIT: 'Шағын кредиттер беру ережелері 01.09.2022',
    MICROFINANCE_LICENSE: 'Микроқаржы қызметіне лицензиясы 31.03.2021'
  },
  REPORTS: {
    FINANCIAL_REPORT_2021: '«Салем Кредит» микроқаржы ұйымы» ЖШС-нің 2021 жылғы қаржылық есептілігі'
  },
  PAYMENT_CONFIRM: {
    TITLE: 'Төлемді растау',
    TEXT: 'Сіздің картаңыздан <b>{paymentAmount}</b> * теңге алынады. Төлемді растайсыз ба?',
    BUTTON: 'Растау',
    COMMISSION_POSTSCRIPT: '+ аударым үшін комиссия'
  },
  PAYMENT_CARD: {
    CURRENT: 'Төлем картасы',
    ADD: 'Жаңа төлем картасын қосыңыз',

    BUTTON: {
      PAY: 'Төлеу',
      SELECT: 'Таңдау',
      ADD: 'Қосу',
      ACCEPT: 'Сақтау'
    },

    APPROVE: {
      TITLE: 'Карта деректерін растау қажет'
    },

    CONFIRMED: {
      TITLE: 'Банк картасы сәтті расталды'
    },

    ACCEPT: {
      SUCCESS: 'Банк картасы сәтті сақталды'
    }
  },
  MULTI_UPLOAD_INPUT: {
    PLACEHOLDER: '+ Құжат қосу',
    TOOLTIP: 'Мәлімдемеңізді қосыңыз'
  }
};
