import { Document } from '@/api/models/document';

export const enum AGREEMENT_DOCUMENT_TYPE {
  AGREEMENT_CONTRACT = 'agreement-contract',
  AGREEMENT_POSTPONEMENT_CONTRACT = 'agreement-postponement-contract',
  AGREEMENT_POSTPONEMENT_CONTRACT_SIGNATURE = 'agreement-postponement-contract-signature',
  UNIFIED_AGREEMENT_CONTRACT = 'unified-agreement-contract',
  UNIFIED_AGREEMENT_CONTRACT_SIGNATURE = 'unified-agreement-contract-signature',
  UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE = 'unified-agreement-contract-online-signature',
  UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE_INFO = 'unified-agreement-contract-online-signature-info',
  UNIFIED_AGREEMENT_INSURANCE_CONTRACT = 'unified-agreement-insurance-contract',
  UNIFIED_AGREEMENT_INSURANCE_CONTRACT_SIGNATURE = 'unified-agreement-insurance-contract-signature',
  UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE = 'unified-agreement-insurance-contract-online-signature',
  UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE_INFO = 'unified-agreement-insurance-contract-online-signature-info',
  APPLICATION_QUESTIONNAIRE = 'application-questionnaire',
  INSURANCE_CONTRACT = 'insurance-contract',
  INSURANCE_APPLICATION = 'insurance-application',
  INSURANCE_TRANSFER_APPLICATION = 'insurance-transfer-application',
  AGREEMENT_CONTRACT_SIGNATURE = 'agreement-contract-signature',
  APPLICATION_QUESTIONNAIRE_SIGNATURE = 'application-questionnaire-signature',
  INSURANCE_CONTRACT_SIGNATURE = 'insurance-contract-signature',
  INSURANCE_APPLICATION_SIGNATURE = 'insurance-application-signature',
  INSURANCE_TRANSFER_APPLICATION_SIGNATURE = 'insurance-transfer-application-signature',
  CLIENT_CONSENT_CREDIT_BUREAU_SHARE = 'client-consent-credit-bureau-share',
  CLIENT_CONSENT_CREDIT_REPORT = 'client-consent-credit-report',
  CLIENT_CONSENT_PROCESS_INCOME = 'client-consent-process-income',
  CLIENT_CONSENT_PERSONAL_DATA = 'client-consent-personal-data',
  AGREEMENT_CONTRACT_AMENDMENT = 'agreement-contract-amendment',
  AGREEMENT_CONTRACT_AMENDMENT_FULL = 'agreement-contract-amendment-full'
}

export const AGREEMENT_CONTRACT_ONLINE_SIGNATURE: string[] = [
  AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT_ONLINE_SIGNATURE,
  AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT_ONLINE_SIGNATURE
];

export const CONSENT_DOCUMENTS_TYPES: string[] = [
  AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_BUREAU_SHARE,
  AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_CREDIT_REPORT,
  AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PROCESS_INCOME,
  AGREEMENT_DOCUMENT_TYPE.CLIENT_CONSENT_PERSONAL_DATA
];

export const UNIFIED_AGREEMENT_DOCUMENTS_TYPES: string[] = [
  AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_CONTRACT,
  AGREEMENT_DOCUMENT_TYPE.UNIFIED_AGREEMENT_INSURANCE_CONTRACT
];

export const SINGLE_AGREEMENT_DOCUMENTS_TYPES: string[] = [
  AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT,
  AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_SIGNATURE,
  AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE,
  AGREEMENT_DOCUMENT_TYPE.APPLICATION_QUESTIONNAIRE_SIGNATURE,
  AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION,
  AGREEMENT_DOCUMENT_TYPE.INSURANCE_TRANSFER_APPLICATION_SIGNATURE
];

export interface AgreementDocuments {
  agreementId: string;
  documentList: Document[];
}

export interface ApplicationDocuments {
  applicationId: string;
  documentList: Document[];
}
