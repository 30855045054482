export interface DebtSettlementApplicationPayload {
  appealText: string;
  contactPhone: string;
  agreementNumber: string;
  loanId: string;
  documentsList: string[];
}

export interface DebtSettlementApplicationInput {
  payload: DebtSettlementApplicationPayload;
}

export interface DebtSettlementApplicationTransitionInput {
  id: string;
  transition: string;
}

export enum DebtSettlementApplicationStatus {
  SUBMITTED = 'submitted',
  DOCUMENTS_REQUESTED = 'documents_requested',
  FIRST_REVIEWED = 'first_reviewed',
  SECOND_REVIEWED = 'second_reviewed',
  FIRST_OFFERED = 'first_offered',
  FIRST_OFFER_EXPIRED = 'first_offer_expired',
  SECOND_OFFERED = 'second_offered',
  SECOND_OFFER_EXPIRED = 'second_offer_expired',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted', // historical status
  ACCEPTED_BY_POSTPONEMENT = 'accepted_by_postponement',
  ACCEPTED_BY_RESCHEDULE = 'accepted_by_reschedule',
  ACCEPTED_BY_WRITE_OFF = 'accepted_by_write_off'
}

export const FINAL_STATUSES = [
  DebtSettlementApplicationStatus.ACCEPTED,
  DebtSettlementApplicationStatus.ACCEPTED_BY_RESCHEDULE,
  DebtSettlementApplicationStatus.ACCEPTED_BY_POSTPONEMENT,
  DebtSettlementApplicationStatus.ACCEPTED_BY_WRITE_OFF,
  DebtSettlementApplicationStatus.CANCELLED,
  DebtSettlementApplicationStatus.REJECTED
];

export interface DebtSettlementApplication {
  id: string;
  clientId: string;
  status: DebtSettlementApplicationStatus;
  payload: DebtSettlementApplicationPayload;
}
